
import { Options, Vue } from "vue-class-component";
import UserCard from "../components/UserCard.vue";
import { courseListStudentsHandler } from "../handlers/course/course-teachers-handlers";
import { isLoggedIn } from "../handlers/handlers-helpers";
import { CourseStudentsType } from "../types/course/course-type";
import { TypeUserListEnum } from "../types/enum/type-user-item";

@Options({
  components: { UserCard },
})
export default class CourseAttendee extends Vue {
  private student_list: Array<CourseStudentsType> = [];

  private type_teacher: TypeUserListEnum = TypeUserListEnum.TEACHER;

  mounted(): void {
    if (isLoggedIn()) {
      courseListStudentsHandler(parseInt(this.$route.params.course_id.toString(), 10))
        .then((response_course_list_taught: Array<CourseStudentsType> | void) => {
          this.student_list = response_course_list_taught || [];
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }
}
